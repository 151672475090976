import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const ReservationForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    date: '',
    time: '',
    duration: 1,
    phone: '',
    numPaddles: 1,
    cost: 25,
    location: 'Kelibia',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'time') {
      // Restrict time between 5 AM and 9 PM
      const selectedTime = new Date(`2000-01-01T${value}`);
      if (selectedTime.getHours() < 5 || selectedTime.getHours() >= 21) {
        setFormData({
          ...formData,
          time: '',
          duration: 1,
        });
        return;
      }
    }

    if (name === 'duration' || name === 'numPaddles') {
      const duration = name === 'duration' ? parseInt(value, 10) : formData.duration;
      const numPaddles = name === 'numPaddles' ? parseInt(value, 10) : formData.numPaddles;
      const cost = 25 * duration * numPaddles;
      setFormData({
        ...formData,
        [name]: parseInt(value, 10),
        cost,
      });
    } else if (name === 'phone' && /^\d{0,8}$/.test(value)) {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check if phone number is exactly 8 digits
    if (formData.phone.length !== 8) {
      toast.error('Le numéro de téléphone doit comporter exactement 8 chiffres.', {
        position: "top-center"
      });
      return;
    }

    const templateParams = {
      name: formData.name,
      date: formData.date,
      time: formData.time,
      duration: formData.duration,
      phone: formData.phone,
      numPaddles: formData.numPaddles,
      cost: formData.cost,
      location: formData.location,
    };

    emailjs.send('service_q3bqppo', 'template_rhqf4pp', templateParams, 'hLzS9pmjTsNSRRl28')
      .then((response) => {
        console.log('SUCCESS!', response.status, response.text);
        toast.success('Réservation effectuée avec succès, nous vous contacterons pour confirmer.', {
          position: "top-center"
        });
      }, (error) => {
        console.error('FAILED...', error);
        toast.error('Échec de la réservation. Veuillez réessayer.', {
          position: "top-center"
        });
      });

    // Reset form fields
    setFormData({
      name: '',
      date: '',
      time: '',
      duration: 1,
      phone: '',
      numPaddles: 1,
      cost: 20,
      location: 'Kelibia',
    });
  };

  const renderTimeOptions = () => {
    const options = [];
    for (let i = 5; i < 21; i++) {
      const hour = `${i < 10 ? '0' : ''}${i}`;
      options.push(
        <option key={hour} value={`${hour}:00`}>{`${hour}:00`}</option>
      );
    }
    return options;
  };

  return (
    <div className="reservation-form">
      <h2>Réservation Paddle Sea Kelibia</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Nom:</label>
          <input type="text" name="name" value={formData.name} onChange={handleChange} required />
        </div>
        <div>
          <label>Date:</label>
          <input type="date" name="date" value={formData.date} onChange={handleChange} min={new Date().toISOString().split('T')[0]} required />
        </div>
        <div>
          <label>Heure:</label>
          <select name="time" value={formData.time} onChange={handleChange} required>
            {renderTimeOptions()}
          </select>
        </div>
        <div>
          <label>Durée (heures):</label>
          <select name="duration" value={formData.duration} onChange={handleChange} required>
            {[...Array(5)].map((_, i) => (
              <option key={i + 1} value={i + 1}>{i + 1}</option>
            ))}
          </select>
        </div>
        <div>
          <label>Nombre de paddles:</label>
          <select name="numPaddles" value={formData.numPaddles} onChange={handleChange} required>
            {[...Array(5)].map((_, i) => (
              <option key={i + 1} value={i + 1}>{i + 1}</option>
            ))}
          </select>
        </div>
        <div>
          <label>Téléphone:</label>
          <input type="tel" name="phone" value={formData.phone} onChange={handleChange} maxLength="8" required />
        </div>
        <div>
          <label>Lieu :</label>
          <input type="text" name="location" value={formData.location} onChange={handleChange} required />
        </div>
        <div>
          <label className="cost">Coût: {formData.cost} dinars</label>
        </div>
        <button type="submit">Réserver</button>
      </form>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default ReservationForm;
