import React from 'react';
import './App.css';
import ReservationForm from './ReservationForm';

function App() {
  return (
    <div className="App">
      <br></br>
      <ReservationForm />
      <br></br>
    </div>
  );
}

export default App;
